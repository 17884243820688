import React, { useState } from 'react'
import styled from 'styled-components'
import { FormControl, Input, MenuItem, Select } from '@material-ui/core'
import { useEffect } from 'react'
import { api } from '@app/utils/api/api'

import { AircraftDetailDto } from '@strafos/common'
import SingleDatePicker from '@app/components/atoms/SingleDatePicker/SingleDatePicker'

export interface ScheduleCalendarFiltersProps {
  aircraftList: AircraftDetailDto[]
  selectedAircraftList: AircraftDetailDto[]
  date?: Date
  initAircraftRegCode?: string
}

interface ScheduleCalendarProps {
  filters: ScheduleCalendarFiltersProps
  onChange: (filters: ScheduleCalendarFiltersProps) => void
}

const ScheduleCalendarFilters = ({
  filters,
  onChange,
}: ScheduleCalendarProps) => {
  const [filter, setFilter] = useState<ScheduleCalendarFiltersProps>(filters)

  const [aircraftList, setAircraftList] = useState<AircraftDetailDto[]>([])
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedAircraftList, setSelectedAircraftList] = useState<
    AircraftDetailDto[]
  >([])

  useEffect(() => {
    api.listAircraft({ limit: 100 }).then((result) => {
      const date = new Date()
      const aircraftResults = result.data.data
      let selectedAircraftResults = aircraftResults
      if (filters.initAircraftRegCode) {
        const initAircraft = aircraftResults.find(
          (curAircraft) =>
            curAircraft.registration_code === filters.initAircraftRegCode,
        )
        if (initAircraft) {
          selectedAircraftResults = [initAircraft]
          const sameCategoryAircraft = aircraftResults.filter(
            (curAircraft) =>
              curAircraft.category === initAircraft.category &&
              initAircraft.id !== curAircraft.id,
          )
          selectedAircraftResults.push(...sameCategoryAircraft)
        }
      }

      setAircraftList(aircraftResults)
      setSelectedAircraftList(selectedAircraftResults)
      setSelectedDate(date)
      setFilter({
        ...filter,
        date: date,
        aircraftList: aircraftResults,
        selectedAircraftList: selectedAircraftResults,
      })
    })
  }, [])

  const handleAircraftChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selection = event.target.value as string[]
    const selectedObjects = aircraftList.filter((aircraft) =>
      selection.includes(aircraft.registration_code),
    )
    setSelectedAircraftList(selectedObjects)
    setFilter({
      ...filter,
      date: selectedDate,
      aircraftList: aircraftList,
      selectedAircraftList: selectedObjects,
    })
  }

  const handleDateChange = (nextValue: Date | null) => {
    if (nextValue) {
      setSelectedDate(nextValue)
      if (aircraftList.length) {
        setFilter({
          ...filter,
          date: nextValue,
        })
      }
    }
  }

  useEffect(() => {
    onChange(filter)
  }, [filter])

  return (
    <Container>
      <FormControl>
        <StyledDatePicker
          size="normal"
          TextFieldProps={{
            name: 'Date',
            inputProps: {
              'data-testid': `ScheduleCalendarFilters-date-input`,
            },
          }}
          value={selectedDate}
          onChange={handleDateChange}
          disablePast={false}
        />
      </FormControl>
      <FormControl>
        <StyledSelect
          labelId="aircraft_select"
          id="aircrraft_input"
          multiple
          value={selectedAircraftList.map(
            (aircraft) => aircraft.registration_code,
          )}
          onChange={handleAircraftChange}
          input={<Input />}
        >
          {aircraftList.map((aircraft) => (
            <MenuItem
              key={aircraft.id}
              value={aircraft.registration_code}
              //   style={getStyles(name, personName, theme)}
            >
              {aircraft.registration_code}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Container>
  )
}

const StyledDatePicker = styled(SingleDatePicker)`
  padding: 2px 6px;
  width: 9rem;
`

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  transition: all 0.5s;
  align-items: center;
`

const StyledSelect = styled(Select)`
  width: 15rem;
`

export default ScheduleCalendarFilters
